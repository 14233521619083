var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("銷售應用中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("分店庫存管理")])],1)],1)])]),_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold"},[_vm._v(" 分店庫存管理 ")]),_c('div',{staticClass:"\n            col-12 col-xl-6\n            d-flex\n            flex-column flex-xl-row\n            align-items-start align-items-xl-center\n          "},[_c('b-form-select',{attrs:{"id":"branch-select","options":_vm.branchOptions},on:{"change":_vm.handleBranchChange},model:{value:(_vm.selectedBranch),callback:function ($$v) {_vm.selectedBranch=$$v},expression:"selectedBranch"}}),_c('b-form-select',{attrs:{"options":_vm.merchants,"disabled":_vm.merchants.length == 2},on:{"change":_vm.handlePageReset},model:{value:(_vm.query.merchant_id),callback:function ($$v) {_vm.$set(_vm.query, "merchant_id", $$v)},expression:"query.merchant_id"}}),_c('b-input-group',{staticClass:"ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"搜尋商品"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handlePageReset.apply(null, arguments)}},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.handlePageReset}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.productSkus,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
              name: 'ProductDetail',
              params: {
                product_id: data.item.product.id,
              },
            }}},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(data.item.product.title == data.item.sku_name ? data.item.product.title : data.item.product.title + " " + data.item.sku_name)+" ")]),_c('div',[_vm._v(_vm._s(data.item.sku_code)+" "+_vm._s(data.item.sku_outer_code))])])]}},{key:"cell(picture)",fn:function(data){return [(
              data.item.product.images[0] &&
              data.item.product.images[0].pic_url
            )?_c('b-img-lazy',{staticClass:"thumb-image",attrs:{"src":data.item.product.images[0] &&
              data.item.product.images[0].pic_url,"alt":"商品圖片","width":"50","height":"50"}}):_vm._e()]}},{key:"cell(max_quantity)",fn:function(data){return [(data.item.isEditing)?_c('div',[_c('b-form-input',{staticStyle:{"max-width":"125px"},attrs:{"type":"number"},model:{value:(data.item.max_quantity),callback:function ($$v) {_vm.$set(data.item, "max_quantity", $$v)},expression:"data.item.max_quantity"}})],1):_c('div',[_vm._v(" "+_vm._s(data.item.max_quantity)+" ")])]}},{key:"cell(quantity)",fn:function(data){return [(data.item.isEditing)?_c('div',[_c('b-form-input',{staticStyle:{"max-width":"125px"},attrs:{"type":"number"},model:{value:(data.item.quantity),callback:function ($$v) {_vm.$set(data.item, "quantity", $$v)},expression:"data.item.quantity"}})],1):_c('div',[_vm._v(" "+_vm._s(data.item.quantity)+" ")])]}},{key:"cell(sold_quantity)",fn:function(data){return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(data.item.max_quantity - data.item.quantity)+" ")])]}},{key:"cell(arrange)",fn:function(data){return [(_vm.checkPermission([_vm.consts.BRANCH_PRODUCT_STOCK_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning"},on:{"click":function($event){data.item.isEditing
                ? _vm.updateProductSkuMaxQuantity(
                    data.item,
                    data.item.max_quantity,
                    data.item.quantity
                  )
                : _vm.toggleEditMode(data.item)}}},[_c('span',{staticClass:"mdi mdi-lead-pencil mr-1"}),_vm._v(" "+_vm._s(data.item.isEditing ? "更新" : "編輯")+" ")]):_vm._e()]}}])}),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.totalRows,"perPage":_vm.query.per_page},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }